import { z } from "zod"

const bannedDomains = [
  "rightbliss.beauty",
  "silesia.life",
  "serviseantilogin.com",
]

export const contactFormSchema = z.object({
  name: z.string().max(255),
  companyName: z.string().max(255),
  email: z
    .string()
    .email()
    .toLowerCase()
    .refine((val) => !bannedDomains.includes(val.split("@")[1])),
  position: z.string().optional(),
  mobileNumber: z.string().trim().optional(),
})
