import { useState, useEffect, useRef } from "react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import { CheckIcon } from "@heroicons/react/20/solid"
import {
  Form,
  Link,
  useFetcher,
  useNavigation,
  useActionData,
} from "@remix-run/react"
import { classNames } from "~/utils/classnames"
import { db } from "~/utils/db.server"
import { DateTime } from "luxon"
import { Progress } from "~/components/ui/progress"
import { Button } from "~/components/ui/button"
import { Input } from "~/components/ui/input"
import { Label } from "~/components/ui/label"
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert"
import { ReloadIcon } from "@radix-ui/react-icons"
import { BarList, Card } from "@tremor/react"
import { BagelhostIcon } from "~/components/icon"
import { speedTestSchema } from "~/schema/speed-test"
import { contactFormSchema } from "~/schema/contact"
import { useForm, getFormProps, getInputProps } from "@conform-to/react"
import { parseWithZod, getZodConstraint } from "@conform-to/zod"
import { sendMessageToEnquiriesChannel } from "~/utils/discord.server"

export const links = () => {
  return [
    {
      rel: "canonical",
      href: "https://bagelhost.com",
    },
  ]
}

export const meta = () => {
  return [
    { title: "BagelHost | Website Hosting Optimized for Speed" },
    {
      name: "description",
      content: "Get a free website speed consultation today!",
    },
    {
      name: "google-site-verification",
      content: "_U8aAlCbTMXDmmL1Q7lWiFzqOPPTj8meVAEHq0uTqPs",
    },
  ]
}

const stats = [
  {
    id: 1,
    name: "of users leave websites slower than 4 seconds",
    value: "25%",
  },
  { id: 2, name: "of users don't revisit slow websites", value: "46%" },
  { id: 3, name: "of shoppers won't purchase again", value: "64%" },
]

const tiers = [
  {
    name: "Light",
    id: "tier-light",
    href: "https://wa.me/+601154403302?text=hi,%20tell%20me%20more%20about%20BagelHost!",
    priceMonthly: "200",
    description:
      "Just the right kind of smooth for websites that want to leave a good impression, unlike the latte ☕️ you had this morning.",
    features: [
      "Performance guarantee",
      "Uptime monitoring",
      "Weekly backups",
      "Support within 24-hours",
    ],
    mostPopular: false,
  },
  {
    name: "Medium",
    id: "tier-medium",
    href: "https://wa.me/+601154403302?text=hi,%20tell%20me%20more%20about%20BagelHost!",
    priceMonthly: "400",
    description:
      "For growing websites where consistency and reliability is key, like a good PB & J 🥪.",
    features: [
      "Performance guarantee",
      "Monthly reporting",
      "99.5% uptime guarantee",
      "Daily backups",
      "Support within 24-hours",
    ],
    mostPopular: true,
  },
  {
    name: "Heavy",
    id: "tier-heavy",
    href: "https://wa.me/+601154403302?text=hi,%20tell%20me%20more%20about%20BagelHost!",
    priceMonthly: "Custom",
    description:
      "For extremely ambitious companies that are aiming for the moon. 🚀",
    features: [
      "Performance guarantee",
      "Real-time analytics",
      "99.9% uptime guarantee",
      "Custom backups",
      "Priority support",
    ],
    mostPopular: false,
  },
]

const generateChartData = (siteReports) => {
  if (!siteReports) {
    return []
  }

  if (!Array.isArray(siteReports)) {
    return []
  }

  const [customerReport, bagelReport] = siteReports
  const customerLoadTime = customerReport.result.pageLoadTime / 1000
  const bagelLoadTime = bagelReport.result.pageLoadTime / 1000

  let summaryMessage
  if (customerLoadTime < 1.5) {
    summaryMessage =
      "✅  Wow! Good job, like seriously but can you keep this up? Let’s have a bagel and talk about it."
  } else if (customerLoadTime >= 1.5 && customerLoadTime <= 3) {
    summaryMessage =
      "⚠️  Pretty good, but you can do better! Let us show you how!"
  } else if (customerLoadTime <= 5) {
    summaryMessage =
      "🚨  Uh oh, you are losing over 90% of your website visitors! Best you contact us!"
  } else if (customerLoadTime <= 6) {
    summaryMessage =
      "🚨  Fix your website soon! You are losing over 106% of your website visitors! Contact us yesterday!"
  } else if (customerLoadTime < 9) {
    summaryMessage =
      "🚨  Oh dear...over 123% of your website visitors are abandoning your site! You need us now, like right now, like seriously! "
  } else if (customerLoadTime > 9) {
    summaryMessage =
      "🚨 You are BROKEN! You took longer than 10 seconds to load. Call us!"
  }

  let data = [
    {
      name: customerReport.url,
      value: customerLoadTime.toFixed(2),
      summaryMessage,
    },
    {
      name: bagelReport.url,
      value: bagelLoadTime.toFixed(2),
      icon: BagelhostIcon,
    },
  ]

  return data
}

export const action = async ({ request }) => {
  const formData = await request.formData()

  const submission = parseWithZod(formData, { schema: contactFormSchema })
  if (submission.status !== "success") {
    return submission.reply()
  }

  const saveEnquiry = await db.enquiry.create({
    data: {
      ...submission.value,
    },
  })

  const sendMessage = sendMessageToEnquiriesChannel({
    message: `New enquiry!\n\n ${JSON.stringify(submission.value, null, "\t")}`,
  })

  return submission.reply()
}

export default function RootIndex() {
  const [timeElapsed, setTimeElapsed] = useState(0)
  const [chartData, setChartData] = useState(generateChartData(null))

  const fetcher = useFetcher({ key: "speed-test" })

  const [speedTestForm, speedTestFields] = useForm({
    constraint: getZodConstraint(speedTestSchema),
    shouldValidate: "onBlur",
    shouldRevalidate: "onInput",
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: speedTestSchema })
    },
  })

  const actionData = useActionData()
  const navigation = useNavigation()
  const [contactForm, contactFormFields] = useForm({
    constraint: getZodConstraint(contactFormSchema),
    shouldValidate: "onBlur",
    shouldRevalidate: "onInput",
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: contactFormSchema })
    },
  })

  // Start a timer when speedTest form is submitted, so we can show some
  // progress bar on the frontend
  const intervalIdRef = useRef(null)
  const maxLoadTime = 20
  const loadProgress = (parseInt(timeElapsed) / maxLoadTime).toFixed(2) * 100
  useEffect(() => {
    if (fetcher.state === "submitting") {
      const start = DateTime.now()
      const intervalId = setInterval(() => {
        setTimeElapsed(DateTime.now().diff(start).toFormat(`ss`))
      }, 1 * 1000)
      intervalIdRef.current = intervalId
    }

    if (fetcher.state === "idle" && fetcher.data) {
      setChartData(generateChartData(fetcher.data))
      setTimeElapsed(maxLoadTime)
      clearInterval(intervalIdRef.current)
    }
  }, [fetcher.state, fetcher.data])

  return (
    <div className="bg-white">
      <main className="isolate">
        {/* Hero section */}
        <div className="relative bg-[#FEF8F4]">
          <div className="z-10 py-14">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h1 className="text-4xl font-bold !leading-tight tracking-tight text-gray-900 sm:text-6xl">
                  Slow websites are bad for business 💸
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  {`Customers don't tolerate slow websites, so you
                  shouldn't either. We'll make your website load faster than you can say "How is that possible?".`}
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <Link
                    to="#contact-us"
                    className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="did-you-know" className="bg-white py-14">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h3 className="text-base font-semibold leading-8 text-orange-600">
                Did you know
              </h3>
              <h2 className="mt-2 text-3xl font-bold !leading-tight tracking-tight text-gray-900 sm:text-4xl">
                {`How does website loading speed affect your business?`}
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {`According to a study* done by Google, there's a `}
                <strong>90% chance</strong>
                {` users will abandon your site if it loads within 5 seconds—and it only gets worse!`}
              </p>
            </div>
            <div className="mt-8 overflow-hidden">
              <div className="mx-auto flex max-w-3xl flex-row gap-4 overflow-auto scroll-smooth scrollbar-hide sm:gap-8 lg:items-end">
                <div className="flex flex-col items-start gap-x-16 gap-y-2 rounded-2xl bg-red-600 p-6 sm:p-8">
                  <div>
                    <p className="flex-none text-3xl font-bold tracking-tight text-white">
                      32%
                    </p>
                    <p className="text-sm font-semibold tracking-tight text-white/95 sm:text-lg">
                      of visitors
                    </p>
                  </div>
                  <div className="shrink lg:w-auto">
                    <p className="mt-2 text-base leading-6 text-white/95">
                      Leave within 3 seconds
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-start gap-x-16 gap-y-2 rounded-2xl bg-red-600 p-6 sm:p-8 lg:flex-auto lg:gap-y-20">
                  <div>
                    <p className="flex-none text-3xl font-bold tracking-tight text-white">
                      90%
                    </p>
                    <p className="text-sm font-semibold tracking-tight text-white/95 sm:text-lg">
                      of visitors
                    </p>
                  </div>
                  <div className="shrink lg:w-auto">
                    <p className="mt-2 text-base leading-6 text-white/95">
                      Leave within 5 seconds
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-start gap-x-16 gap-y-2 rounded-2xl bg-red-600 p-6 sm:p-8 lg:flex-auto lg:gap-y-40">
                  <div>
                    <p className="flex-none text-3xl font-bold tracking-tight text-white">
                      106%
                    </p>
                    <p className="text-sm font-semibold tracking-tight text-white/95 sm:text-lg">
                      of visitors
                    </p>
                  </div>
                  <div className="shrink lg:w-auto">
                    <p className="mt-2 text-base leading-6 text-white/95">
                      Leave within 6 seconds
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-start gap-x-16 gap-y-2 rounded-2xl bg-red-600 p-6 sm:p-8 lg:flex-auto lg:gap-y-60">
                  <div>
                    <p className="flex-none text-3xl font-bold tracking-tight text-white">
                      123%
                    </p>
                    <p className="text-sm font-semibold tracking-tight text-white/95 sm:text-lg">
                      of visitors
                    </p>
                  </div>
                  <div className="shrink lg:w-auto">
                    <p className="mt-2 text-base leading-6 text-white/95">
                      Leave within 10 seconds
                    </p>
                  </div>
                </div>
              </div>
              <p className="mt-8 text-center text-sm">
                *Source:{` `}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.thinkwithgoogle.com/marketing-strategies/app-and-mobile/mobile-page-speed-new-industry-benchmarks/"
                  className="text-blue-600 underline underline-offset-4"
                >
                  Google/SOASTA Research, 2017.
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div id="speed-test" className="bg-[#FEF8F4]">
          <div className="px-6 pb-8 pt-14 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <div className="flex justify-center">
                <dotlottie-player
                  src="/assets/lightning-lottie.json"
                  speed="1"
                  style={{ width: "80px", height: "80px" }}
                  loop
                  autoplay
                  direction="1"
                  mode="normal"
                />
              </div>
              <h2 className="text-pretty text-3xl font-bold !leading-tight tracking-tight text-black sm:text-4xl">
                How much faster could my website be?
              </h2>
              <div className="mx-auto max-w-xl">
                <p className="my-6 text-pretty text-lg leading-8 text-black/95">
                  {`Type in your website link below for a free speed test! If your website is quicker than us, we'll buy you a bagel.`}
                </p>
              </div>
              <Form
                action="/speed-test"
                fetcherKey="speed-test"
                method="POST"
                navigate={false}
                replace
                preventScrollReset
                className="mt-6 flex flex-col items-center justify-center"
                {...getFormProps(speedTestForm)}
              >
                <div className="flex w-full max-w-sm flex-col">
                  <div className="flex items-center space-x-2">
                    <Input
                      className="bg-white"
                      placeholder="e.g. www.nike.com"
                      {...getInputProps(speedTestFields.url, { type: "text" })}
                    />
                    <Button
                      type="submit"
                      className="bg-orange-600"
                      disabled={fetcher.state === "submitting"}
                    >
                      {fetcher.state === "submitting" ? (
                        <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                      ) : null}
                      Start Test
                    </Button>
                  </div>
                  <div
                    className={classNames(
                      speedTestFields.url.errors ? "" : "invisible",
                      "mt-4 flex items-start justify-center text-red-600",
                    )}
                  >
                    <ExclamationTriangleIcon className="mr-2 size-6 shrink-0" />
                    <p id={speedTestFields.url.errorId} className="text-left">
                      {speedTestFields.url.errors}
                    </p>
                  </div>
                </div>
              </Form>
              <div
                className={classNames(
                  fetcher.state === "submitting" || fetcher.data
                    ? ""
                    : "invisible",
                  "relative mx-auto mb-2 mt-4 flex w-[90%] max-w-lg items-center transition-all",
                )}
              >
                <img
                  src="/assets/turtle-icon.png"
                  alt="loading spinner"
                  style={{
                    left: `${loadProgress - 4}%`,
                  }}
                  className="absolute z-10 size-8 animate-spin transition-all"
                />
                <Progress value={loadProgress} className="my-5" />
              </div>
              {fetcher.data?.error && fetcher.state !== "submitting" ? (
                <Alert
                  variant="destructive"
                  className=" mb-4 bg-red-100 text-red-600"
                >
                  <ExclamationTriangleIcon className="h-4 w-4" />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{fetcher.data?.error}</AlertDescription>
                </Alert>
              ) : null}
              {chartData.length > 0 ? (
                <Card className="mx-auto max-w-lg">
                  <h3 className="text-tremor-title font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    Speed Test Comparison
                  </h3>
                  <p className="mt-4 flex items-center justify-between text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    <span>Website URL</span>
                    <span>Seconds</span>
                  </p>
                  <BarList data={chartData} className="mt-2" />
                  <p className="mt-4">
                    {chartData.length > 0
                      ? `${chartData[0]?.summaryMessage}`
                      : null}
                  </p>
                  <div className="mt-4 flex justify-center">
                    <Link
                      to="#contact-us"
                      className="rounded-md bg-orange-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                    >
                      Contact Us
                    </Link>
                  </div>
                </Card>
              ) : null}
            </div>
          </div>
        </div>

        {/* Pricing section */}
        {/* use whatsapp link on all contact-us links */}
        <div id="pricing" className="py-14">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl text-center">
              <h3 className="text-base font-semibold leading-7 text-orange-600">
                How much will it cost?
              </h3>
              <h2 className="mt-2 text-pretty text-4xl font-bold !leading-tight tracking-tight text-gray-900 sm:text-5xl">
                Pricing plans for websites of all sizes
              </h2>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
              {`Don't worry too much about figuring out which plan you'll need—all plans come with a free website audit to determine which one's for you.`}
            </p>
            <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {tiers.map((tier, tierIdx) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? "border-2 border-orange-600 bg-[#FEF8F4] drop-shadow-xl lg:z-10 lg:rounded-b-none"
                      : "border border-black/90 bg-white lg:mt-8",
                    tierIdx === 0 ? "lg:rounded-r-none" : "",
                    tierIdx === tiers.length - 1 ? "lg:rounded-l-none" : "",
                    "flex flex-col justify-between rounded-3xl p-8 ring-1 ring-gray-200 xl:p-10",
                  )}
                >
                  <div>
                    <div className="flex items-center justify-between gap-x-4">
                      <h3
                        id={tier.id}
                        className={classNames(
                          tier.mostPopular
                            ? "text-orange-600"
                            : "text-gray-900",
                          "font-sans text-3xl font-semibold leading-8",
                        )}
                      >
                        {tier.name}
                      </h3>
                      {tier.mostPopular ? (
                        <p className="rounded-full bg-orange-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-orange-600">
                          Most popular
                        </p>
                      ) : null}
                    </div>
                    <p className="mt-4 text-sm leading-6 text-gray-600">
                      {tier.description}
                    </p>
                    <p className="mt-6 flex items-baseline gap-x-1">
                      <span className="text-2xl font-medium tracking-tight text-gray-900">
                        {`${Number.isInteger(parseInt(tier.priceMonthly)) ? "RM" : ""}${tier.priceMonthly}`}
                      </span>
                      {Number.isInteger(parseInt(tier.priceMonthly)) ? (
                        <span className="text-sm font-medium leading-6 text-gray-600">
                          /month
                        </span>
                      ) : null}
                    </p>
                    <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3 text-pretty">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-orange-600"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <a
                    href={tier.href}
                    target="_blank"
                    rel="noreferrer"
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "bg-orange-600 text-white shadow-sm hover:bg-orange-500"
                        : "border border-orange-600 text-orange-600 ring-1 ring-inset ring-orange-200 hover:ring-orange-300",
                      "mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 sm:hidden",
                    )}
                  >
                    Contact Us
                  </a>
                  <a
                    href="https://bagelhost.com/#contact-us"
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "bg-orange-600 text-white shadow-sm hover:bg-orange-500"
                        : "border border-orange-600 text-orange-600 ring-1 ring-inset ring-orange-200 hover:ring-orange-300",
                      "mt-8 hidden rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 sm:block",
                    )}
                  >
                    Contact Us
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-white pb-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="items-center gap-x-8 gap-y-16">
              <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold !leading-tight tracking-tight text-gray-900">
                  Your speed, guaranteed.
                </h2>
                <div className="mt-6 text-lg leading-8 text-gray-600">
                  <p className="inline">
                    {`All our plans come with a money-back guarantee, which basically means your website will remain`}
                  </p>
                  <em className="inline px-2">fast</em>
                  <p className="inline">{`throughout your subscription with us—because we want to avoid giving you a refund 😉.`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div id="contact-us" className="bg-[#FEF8F4]">
          <div className="px-6 py-14 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold !leading-tight tracking-tight text-black/95 sm:text-4xl">
                Get a free website speed consultation
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-pretty text-lg leading-8 text-black/95">
                {`Fill in the form below, and we'll be in touch. You won't regret it, but your competitors will!`}
              </p>
            </div>
            <Form
              method="POST"
              replace
              preventScrollReset
              className="mt-8 flex items-center justify-center"
              {...getFormProps(contactForm)}
            >
              <div className="flex w-full max-w-sm items-center space-x-2">
                <div className="flex w-full max-w-sm flex-col items-start gap-y-6">
                  <div className="flex w-full flex-col gap-y-2">
                    <Label
                      htmlFor={contactFormFields.name.id}
                      className="text-black/90"
                    >
                      Your Name*
                    </Label>
                    <Input
                      placeholder="Your Name"
                      className="bg-white"
                      {...getInputProps(contactFormFields.name, {
                        type: "text",
                      })}
                    />
                    <div
                      className={classNames(
                        contactFormFields.name.errors ? "" : "hidden",
                        "flex items-start justify-start text-red-600/95",
                      )}
                    >
                      <ExclamationTriangleIcon className="mr-2 size-6 shrink-0" />
                      <p
                        id={contactFormFields.name.errorId}
                        className="text-left"
                      >
                        {contactFormFields.name.errors}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full flex-col gap-y-2">
                    <Label
                      htmlFor={contactFormFields.companyName.id}
                      className="text-black/90"
                    >
                      Company Name*
                    </Label>
                    <Input
                      placeholder="Company Name"
                      className="bg-white"
                      {...getInputProps(contactFormFields.companyName, {
                        type: "text",
                      })}
                    />
                    <div
                      className={classNames(
                        contactFormFields.companyName.errors ? "" : "hidden",
                        "flex items-start justify-start text-red-600/95",
                      )}
                    >
                      <ExclamationTriangleIcon className="mr-2 size-6 shrink-0" />
                      <p
                        id={contactFormFields.companyName.errorId}
                        className="text-left"
                      >
                        {contactFormFields.companyName.errors}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full flex-col gap-y-2">
                    <Label
                      htmlFor={contactFormFields.email.id}
                      className="text-black/90"
                    >
                      Email Address*
                    </Label>
                    <Input
                      placeholder="Email"
                      className="bg-white"
                      {...getInputProps(contactFormFields.email, {
                        type: "email",
                      })}
                    />
                    <div
                      className={classNames(
                        contactFormFields.email.errors ? "" : "hidden",
                        "flex items-start justify-start text-red-600/95",
                      )}
                    >
                      <ExclamationTriangleIcon className="mr-2 size-6 shrink-0" />
                      <p
                        id={contactFormFields.email.errorId}
                        className="text-left"
                      >
                        {contactFormFields.email.errors}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full flex-col gap-y-2">
                    <Label
                      htmlFor={contactFormFields.position.id}
                      className="text-black/90"
                    >
                      Position (optional)
                    </Label>
                    <Input
                      placeholder="Position (optional)"
                      className="bg-white"
                      {...getInputProps(contactFormFields.position, {
                        type: "text",
                      })}
                    />
                    <div
                      className={classNames(
                        contactFormFields.position.errors ? "" : "hidden",
                        "flex items-start justify-start text-red-600/95",
                      )}
                    >
                      <ExclamationTriangleIcon className="mr-2 size-6 shrink-0" />
                      <p
                        id={contactFormFields.position.errorId}
                        className="text-left"
                      >
                        {contactFormFields.position.errors}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full flex-col gap-y-2">
                    <Label
                      htmlFor={contactFormFields.mobileNumber.id}
                      className="text-black/90"
                    >
                      Mobile Number (optional)
                    </Label>
                    <Input
                      placeholder="Mobile Number (optional)"
                      className="bg-white"
                      {...getInputProps(contactFormFields.mobileNumber, {
                        type: "text",
                      })}
                    />
                    <div
                      className={classNames(
                        contactFormFields.mobileNumber.errors ? "" : "hidden",
                        "flex items-start justify-start text-red-600/95",
                      )}
                    >
                      <ExclamationTriangleIcon className="mr-2 size-6 shrink-0" />
                      <p
                        id={contactFormFields.mobileNumber.errorId}
                        className="text-left"
                      >
                        {contactFormFields.mobileNumber.errors}
                      </p>
                    </div>
                  </div>

                  {actionData?.status === "success" ? (
                    <div className="flex items-start justify-center text-green-600">
                      <CheckIcon className="mr-2 size-6 shrink-0" />
                      <p className="text-left text-base">
                        {`Your enquiry is sent! We'll be in touch shortly. Thank you.`}
                      </p>
                    </div>
                  ) : (
                    <Button
                      type="submit"
                      className="mt-2 self-center bg-orange-600"
                      disabled={navigation.state === "submitting"}
                    >
                      {navigation.state === "submitting" ? (
                        <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                      ) : null}
                      Send Now
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
        <script
          async
          src="https://unpkg.com/@dotlottie/player-component@2.7.12/dist/dotlottie-player.mjs"
          type="module"
        />
      </main>
    </div>
  )
}
