import { z } from "zod"
import isURL from "validator/lib/isURL"
import contains from "validator/lib/contains"

export const speedTestSchema = z.object({
  url: z
    .string({
      required_error: "Type in a valid website, like nike.com",
    })
    .toLowerCase()
    .superRefine((val, ctx) => {
      if (
        !isURL(val, {
          require_protocol: false,
          require_valid_protocol: true,
          protocols: ["http", "https"],
          require_tld: true,
          require_host: true,
        })
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Type in a valid website, like www.nike.com",
          fatal: true,
        })
      }
    })
    .refine((val) => !contains(val, "bagelhost"), {
      message:
        "Well someone thought they were pretty smart 😉, type in your own website's URL, not ours!",
    })
    .transform((val) => {
      const protocol = new RegExp("^(http|https)://", "i")

      // add protocol to start of url if it's missing
      if (!val.match(protocol)) {
        return `https://${val}`
      } else {
        return val
      }
    }),
})
